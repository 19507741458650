import { matchfyClient } from '@/lib'

const addArtist = async (artist) => {
  const response = await matchfyClient.post('artists', artist)
  return response.data
}

const deleteArtist = async (artistId) => {
  const response = await matchfyClient.delete(`artists/${artistId}`)
  return response.data
}

const endpoints = {
  addArtist,
  deleteArtist,
}

export default endpoints
