import { PER_PAGE } from '@/data'
import { matchfyClient } from '@/lib'

const getCurator = async (userId) => {
  const response = await matchfyClient.get(`/curators/${userId}`)
  return response.data
}

export const getCuratorPlaylists = async (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)
  const { userId } = params

  const response = await matchfyClient.get(`/curators/${userId}/playlists`, {
    params: {
      limit,
      offset
    }
  })
  return response.data
}

const getCuratorsByType = async (type) => {
  const response = await matchfyClient.get(`/curators/type/${type}`)
  return response.data
}

export const getCuratorArtists = async (userId) => {
  const response = await matchfyClient.get(`/curators/${userId}/artists`)
  return response.data
}

export const getCuratorCounters = async (userId, params) => {
  const response = await matchfyClient.get(`/curators/${userId}/counters`, {
    params
  })

  return response.data
}

export const getCuratorStats = async (userId) => {
  const response = await matchfyClient.get(`/curators/${userId}/stats`)
  return response.data
}

const endpoints = {
  getCurator,
  getCuratorArtists,
  getCuratorCounters,
  getCuratorPlaylists,
  getCuratorsByType,
  getCuratorStats
}

export default endpoints